<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container fluid class="py-0">
      <v-row align-content="center" justify="center" class="mx-0">

        <v-col cols="12" class="main_card_col">
          <v-card elevation="3">
            <v-card-title>
              <span class="mb_max_width">Animals</span>
              <v-spacer class="mb_hidden"></v-spacer>

              <v-switch
                class="mt-0 pt-0 mr-6 ml-1"
                v-model="editable"
                label="Edit"
                color="primary"
                value="Edit"
                inset
                v-if="allowed_operations == 'WRITE'"
                hide-details
                data-cy="btn_edit"
              ></v-switch>

              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-switch
                      class="mt-0 pt-0 mr-6"
                      v-model="editable"
                      label="Edit"
                      color="primary"
                      value="Edit"
                      inset
                      disabled
                      hide-details
                    ></v-switch>
                  </div>
                </template>
                <span>You don't have permission to edit this data</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mt-0 pt-0 mr-4"
                    @click="download_csv"
                    large
                    color="primary"
                    >mdi-download-circle</v-icon
                  >
                </template>
                <span> Download CSV</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    data-cy="btn_livestock_add_animal"
                    v-bind="attrs"
                    v-on="on"
                    class="mt-0 pt-0 mr-4"
                    @click="show_heads = !show_heads"
                    large
                    color="primary"
                    >{{ show_heads ? "mdi-image-off" : "mdi-image" }}</v-icon
                  >
                </template>
                <span>{{
                  show_heads ? "Turn Off Images" : "Show Images"
                }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="sites && sites.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    data-cy="btn_livestock_add_animal"
                    v-bind="attrs"
                    v-on="on"
                    class="mt-0 pt-0 mr-4"
                    @click="add_animal"
                    large
                    color="primary"
                    v-if="allowed_operations == 'WRITE'"
                    >mdi-plus-circle</v-icon
                  >
                  <div v-else v-bind="attrs" v-on="on">
                    <v-icon
                      class="mt-0 pt-0 mr-4"
                      large
                      disabled
                      color="primary"
                      >mdi-plus-circle</v-icon
                    >
                  </div>
                </template>
                <span v-if="allowed_operations == 'WRITE'">Add Animal</span>
                <span v-else>You don't have permission to add animals</span>
              </v-tooltip>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mt-0 pt-0 mr-4"
                    large
                    color="oc_grey"
                    >mdi-plus-circle</v-icon
                  >
                </template>
                <span>Please create a Site first</span>
              </v-tooltip>

              <div class="table_search_field mb_max_width">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="pt-0 mt-0 assign_select assign_select_v font_400 rounded-lg"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  height="32"
                  full-width
                  outlined
                ></v-text-field>
              </div>
              <v-col cols="12" class="px-0 pb-0 pt-1">
                <div style="width: 100%; float: left" class="mb_max_width">
                  <v-chip
                    v-for="(herd, index) in herds[0]"
                    :key="index"
                    active
                    @mouseover="hovered_chip = herd.value"
                    @mouseleave="hovered_chip = null"
                    :disabled="!(allowed_operations == 'WRITE')"
                    :class="
                      (herd.value == selected_herd ? 'selected_chip' : '') +
                      ' mr-2'
                    "
                    @click="
                      !(herd.value == selected_herd)
                        ? (selected_herd = herd.value)
                        : (selected_herd = false)
                    "
                  >
                    {{ herd.text }}
                    <v-icon
                      style="z-index: 10"
                      @click.stop="
                        assign_remove_herd(
                          herd.value == selected_herd ? null : herd.value
                        )
                      "
                      small
                      class="ml-1"
                      v-if="
                        herd.value &&
                        selected_animals &&
                        selected_animals.length
                      "
                      :disabled="!(allowed_operations == 'WRITE')"
                    >
                      {{
                        selected_herd && herd.value == selected_herd
                          ? "mdi-playlist-remove"
                          : "mdi-plus"
                      }}
                    </v-icon>
                    <v-icon
                      style="z-index: 10"
                      @click.stop="edit_herd(herd)"
                      small
                      class="ml-1"
                      v-show="hovered_chip == herd.value"
                      v-if="herd.value"
                      :disabled="!(allowed_operations == 'WRITE')"
                    >
                      mdi-pencil
                    </v-icon>
                  </v-chip>
                  <v-chip
                    @click="herd_dialog = true"
                    active
                    :disabled="!(allowed_operations == 'WRITE')"
                    class="mr-2"
                  >
                    +
                  </v-chip>
                </div>
              </v-col>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                no-data-text="No data available"
                :loading="loading"
                show-select
                v-model="selected_animals"
                loading-text="Loading... Please wait"
                :headers="selected_headers"
                :items="animals"
                :items-per-page="100"
                :server-items-length="animal_count"
                class="elevation-1 livestock_table"
                hide-default-footer
                :search="search"
                :sort-by.sync="sort_by"
                :sort-desc.sync="sort_desc"
                show-expand
                :options.sync="options"
                single-expand
                @click:row="select_animal"
                :expanded.sync="expanded"
              >
                <template v-slot:[`body.prepend`]>
                  <tr class="mb_hidden" v-if="selected_table == 0">
                    <td></td>
                    <td class="select_width align_left pl-2">
                      <v-select
                        class="assign_select"
                        :items="[
                          'All',
                          { value: 'BLACK', text: 'Black' },
                          { value: 'BLUE', text: 'Blue' },
                          { value: 'BROWN', text: 'Brown' },
                          { value: 'GREY', text: 'Grey' },
                          { value: 'GREEN', text: 'Green' },
                          { value: 'ORANGE', text: 'Orange' },
                          { value: 'PINK', text: 'Pink' },
                          { value: 'PURPLE', text: 'Purple' },
                          { value: 'RED', text: 'Red' },
                          { value: 'WHITE', text: 'White' },
                          { value: 'YELLOW', text: 'Yellow' },
                          { value: 'UNDEFINED', text: 'Undefined' },
                        ]"
                        v-model="tag_color_filter"
                        dense
                        height="32"
                        outlined
                        rounded
                        hide-details
                      ></v-select>
                    </td>
                    <td></td>
                    <td class="select_width align_left pl-2">
                      <v-select
                        class="assign_select"
                        :items="[
                          'All',
                          { value: 'FEMALE', text: 'Female' },
                          { value: 'MALE', text: 'Male' },
                        ]"
                        v-model="sex_filter"
                        dense
                        height="32"
                        outlined
                        rounded
                        hide-details
                      ></v-select>
                    </td>
                    <td class="select_width align_left pl-2">
                      <v-select
                        class="assign_select"
                        :items="[
                          'All',
                          { value: 'BULL', text: 'Bull' },
                          { value: 'CALF', text: 'Calf' },
                          { value: 'COW', text: 'Cow' },
                          { value: 'FREEMARTIN', text: 'Freemartin' },
                          { value: 'HEIFER', text: 'Heifer' },
                          { value: 'STEER', text: 'Steer' },
                          { value: 'OTHER', text: 'Other' },
                          { value: 'UNDEFINED', text: 'Undefined' },
                        ]"
                        v-model="classification_filter"
                        dense
                        height="32"
                        outlined
                        rounded
                        hide-details
                      ></v-select>
                    </td>
                    <td class="select_width align_left pl-2">
                      <v-select
                        class="assign_select"
                        :items="herds[1]"
                        v-model="selected_herd"
                        dense
                        height="32"
                        outlined
                        rounded
                        hide-details
                      ></v-select>
                    </td>
                    <td></td>
                    <td class="select_width align_left pl-2">
                      <v-select
                        class="assign_select"
                        :items="[
                          'All',
                          { value: 'ACTIVE', text: 'Active' },
                          { value: 'DEAD', text: 'Dead' },
                          { value: 'EXPORTED', text: 'Exported' },
                          { value: 'OTHER', text: 'Other' },
                          { value: 'UNDEFINED', text: 'Undefined' },
                        ]"
                        v-model="status_filter"
                        dense
                        height="32"
                        outlined
                        rounded
                        hide-details
                      ></v-select>
                    </td>

                    <td></td>
                    <td></td>

                    <td></td>
                  </tr>
                  <tr class="mb_hidden" v-if="selected_table == 1">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="select_width align_left pl-2">
                      <v-select
                        class="assign_select"
                        :items="[
                          'All',
                          { value: 'BEEF', text: 'Beef' },
                          { value: 'BREEDING', text: 'Breeding' },
                          { value: 'DAIRY', text: 'Dairy' },
                          { value: 'OTHER', text: 'Other' },
                          { value: 'UNDEFINED', text: 'Undefined' },
                        ]"
                        v-model="purpose_filter"
                        dense
                        height="32"
                        outlined
                        rounded
                        hide-details
                      ></v-select>
                    </td>
                    <td class="select_width align_left pl-2">
                      <v-select
                        class="assign_select"
                        :items="[
                          'All',
                          { value: 'PUREBRED', text: 'Purebred' },
                          { value: 'COMMERCIAL', text: 'Commercial' },
                          { value: 'OTHER', text: 'Other' },
                          { value: 'UNDEFINED', text: 'Undefined' },
                        ]"
                        v-model="production_filter"
                        dense
                        height="32"
                        outlined
                        rounded
                        hide-details
                      ></v-select>
                    </td>
                    <td class="select_width align_left pl-2">
                      <v-select
                        class="assign_select"
                        :items="[
                          'All',

                          { value: 'PURCHASED', text: 'Purchased' },
                          { value: 'LEASED', text: 'Leased' },
                          { value: 'OWNED', text: 'Owned' },
                          { value: 'OTHER', text: 'Other' },
                          { value: 'UNDEFINED', text: 'Undefined' },
                        ]"
                        v-model="ownership_filter"
                        dense
                        height="32"
                        outlined
                        rounded
                        hide-details
                      ></v-select>
                    </td>
                    <td class="select_width align_left pl-2">
                      <v-select
                        class="assign_select"
                        :items="[
                          'All',
                          { value: 'UNASISSTED', text: 'Unassisted Delivery' },
                          { value: 'EASY_PULL', text: 'Easy Pull' },
                          { value: 'HARD_PULL', text: 'Hard Pull' },
                          { value: 'MALPRESENTATION', text: 'Malpresentation' },
                          { value: 'SURGICAL', text: 'Surgical' },
                          { value: 'UNDEFINED', text: 'Undefined' },
                        ]"
                        v-model="birth_filter"
                        dense
                        height="32"
                        outlined
                        rounded
                        hide-details
                      ></v-select>
                    </td>
                    <td class="select_width align_left pl-2">
                      <v-select
                        class="assign_select"
                        :items="[
                          'All',
                          { value: 'SINGLE_NURSED_OWN', text: 'Single Nursed' },
                          { value: 'FOSTERED', text: 'Fostered' },
                          {
                            value: 'TWIN_NURSED_OWN_TOGETHER',
                            text: 'Twin Nursed Together',
                          },
                          {
                            value: 'TWIN_NURSED_OWN_ALONE',
                            text: 'Twin Nursed Alone',
                          },
                          { value: 'UNDEFINED', text: 'Undefined' },
                        ]"
                        v-model="nursing_filter"
                        dense
                        height="32"
                        outlined
                        rounded
                        hide-details
                      ></v-select>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </template>

                <template v-slot:top="{ pagination, options, updateOptions }">
                  <div class="oc_table_header">
                    <v-tabs
                      class="table_tabs animal_tabs pt-1"
                      v-model="selected_table"
                    >
                      <v-tab data-cy="animal_tab_general">General</v-tab>
                      <v-tab data-cy="animal_tab_history">History</v-tab>
                      <!-- <v-tab data-cy="animal_tab_breeding">Breeding</v-tab> -->
                      <v-tab data-cy="animal_tab_pedigree">Pedigree</v-tab>
                      <v-tab>Measurements</v-tab>
                      <v-tab>Phenotypes</v-tab>
                    </v-tabs>
                    <v-data-footer
                      :pagination="pagination"
                      :options="options"
                      class="table_pagination"
                      @update:options="updateOptions"
                      :items-per-page-options="[50, 100, 200]"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                  </div>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="px-0">
                    <v-data-table
                      data-cy="notes_table_animal"
                      :loading="loading"
                      loading-text="Loading... Please wait"
                      :headers="headers_notes"
                      :items="item.notes"
                      :items-per-page="10"
                      style="border-radius: 0"
                      class="elevation-1"
                      hide-default-footer
                      dense
                    >
                      <template
                        v-slot:top="{ pagination, options, updateOptions }"
                      >
                        <div style="width: 100%; text-align: left">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                :disabled="!(allowed_operations == 'WRITE')"
                                v-bind="attrs"
                                v-on="on"
                                class="ml-3 mt-2"
                                @click="
                                  note_dialog = true;
                                  note_text = '';
                                  note_index = item;
                                "
                                large
                                color="primary"
                                >mdi-plus-circle</v-icon
                              >
                            </template>
                            <span>Add Note</span>
                          </v-tooltip>

                          <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            class="table_pagination_notes"
                            @update:options="updateOptions"
                            :items-per-page-options="[5]"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                          />
                        </div>
                      </template>
                      <template v-slot:[`item.datetime`]="{ item }">
                        {{ item.datetime.split("T")[0] }}
                      </template>

                      <template v-slot:[`item.comment`]="{ index }">
                        <v-edit-dialog
                          @save="update_note(item, index)"
                          @cancel="edit_value_note = null"
                          large
                          @open="edit_value_note = item.notes[index].comment"
                        >
                          {{ item.notes[index].comment }}
                          <template v-slot:input>
                            <v-text-field
                              outlined
                              hide-details
                              dense
                              v-model="edit_value_note"
                              label="Note"
                              class="mt-2"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.delete_note`]="{ item, index }">
                        <v-icon
                          style="z-index: 10"
                          @click.stop="
                            delete_note_id = { id: item.id, index: index };
                            delete_note_dialog = true;
                          "
                          :disabled="allowed_operations != 'WRITE'"
                        >
                          mdi-delete-outline
                        </v-icon>
                      </template>
                    </v-data-table>
                  </td>
                </template>
                <template v-slot:[`item.tag_label`]="{ item, index }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'tag', index)"
                    @cancel="cancel_edit"
                    large
                    @open="
                      open_field(
                        {
                          tag_label: item.tag_label,
                          tag_color: item.tag_color,
                          tag_id: item.tag_id,
                          create_new: false,
                        },
                        'tag'
                      )
                    "
                    @close="close_edit"
                  >
                    <v-img
                      v-if="show_heads"
                      height="76px"
                      class="ma-1"
                      style="border-radius: 12px"
                      aspect-ratio="1"
                      :src="
                        item.image_id
                          ? cropped_url(
                              item.image_id,
                              item.image_timestamp,
                              item.customer_id,
                              'head'
                            )
                          : placeholder_img
                      "
                    >
                      <v-overlay
                        opacity="0"
                        absolute
                        style="
                          justify-content: start;
                          align-items: start;
                          pointer-events: none;
                        "
                      >
                        <v-chip
                          style="
                            height: 24px;
                            margin-bottom: -4px;
                            margin-top: -5px;
                            padding-top: 2px;
                            padding-left: 5px;
                            padding-right: 5px;
                            border-radius: 9px;
                          "
                          v-if="item.tag_label"
                          :color="
                            item.tag_color && item.tag_color != 'UNDEFINED'
                              ? 'oc_' +
                                item.tag_color.toLowerCase() +
                                ' darken-1'
                              : 'grey darken-1'
                          "
                        >
                          <span
                            style="
                              color: white;
                              font-weight: 500;
                              font-size: 12px;
                            "
                            >{{ item.tag_label }}</span
                          ></v-chip
                        >
                      </v-overlay>
                    </v-img>

                    <v-chip
                      style="
                        height: 24px;
                        margin-bottom: -2px;
                        margin-top: -3px;
                        padding-top: 2px;
                      "
                      v-else-if="item.tag_label"
                      :color="
                        item.tag_color && item.tag_color != 'UNDEFINED'
                          ? 'oc_' + item.tag_color.toLowerCase() + ' darken-1'
                          : 'grey darken-1'
                      "
                    >
                      <span style="color: white; font-weight: 500">{{
                        item.tag_label
                      }}</span></v-chip
                    >
                    <span v-else>-</span>

                    <template v-slot:input v-if="edit_value">
                      <v-text-field
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value.tag_label"
                        label="Name"
                        :rules="[validateNotEmpty]"
                      ></v-text-field>

                      <v-autocomplete
                        outlined
                        dense
                        v-model="edit_value.tag_color"
                        label="Color"
                        :items="enums.tag_color"
                        hide-no-data
                        hide-details
                      ></v-autocomplete>
                      <v-checkbox
                        :readonly="edit_value.tag_id == null"
                        v-model="edit_value.create_new"
                        label="Create new?"
                        hide-details
                      ></v-checkbox>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.rfid_current`]="{ item, index }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'rfid_current', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.rfid_current)"
                    @close="close_edit"
                  >
                    {{ capitalizeFirstLetter(item.rfid_current) }}
                    <template v-slot:input>
                      <v-text-field
                        :rules="[rules.rfid]"
                        v-model="edit_value"
                        label="RFID"
                        class="mt-4"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.dam_tag_label`]="{ item, index }">
                  <v-edit-dialog
                    :key="item.id"
                    @save="
                      save_record(item.id, 'lineage', index, item.customer_id)
                    "
                    @cancel="cancel_edit"
                    large
                    @open="
                      open_field(
                        {
                          dam: { value: item.dam_id, text: item.dam_tag_label },
                          sire: {
                            value: item.sire_id,
                            text: item.sire_tag_label,
                          },
                        },
                        'lineage',
                        item.dob
                      )
                    "
                    @close="close_edit"
                  >
                    <v-img
                      v-if="show_heads && item.dam_id"
                      height="76px"
                      class="ma-1"
                      style="border-radius: 12px"
                      aspect-ratio="1"
                      :src="
                        item.dam_image_id
                          ? cropped_url(
                              item.dam_image_id,
                              item.dam_image_timestamp,
                              item.customer_id,
                              'head'
                            )
                          : placeholder_img_dam
                      "
                    >
                      <v-overlay
                        opacity="0"
                        absolute
                        style="
                          justify-content: start;
                          align-items: start;
                          pointer-events: none;
                        "
                      >
                        <v-chip
                          style="
                            height: 24px;
                            margin-bottom: -4px;
                            margin-top: -5px;
                            padding-top: 2px;
                            padding-left: 5px;
                            padding-right: 5px;
                            border-radius: 9px;
                          "
                          v-if="item.dam_tag_label"
                          :color="
                            item.dam_tag_color &&
                            item.dam_tag_color != 'UNDEFINED'
                              ? 'oc_' +
                                item.dam_tag_color.toLowerCase() +
                                ' darken-1'
                              : 'grey darken-1'
                          "
                        >
                          <span
                            style="
                              color: white;
                              font-weight: 500;
                              font-size: 12px;
                            "
                            >{{ item.dam_tag_label }}</span
                          ></v-chip
                        >
                      </v-overlay>
                    </v-img>

                    <v-chip
                      style="
                        height: 24px;
                        margin-bottom: -2px;
                        margin-top: -3px;
                        padding-top: 2px;
                      "
                      v-else-if="item.dam_tag_label"
                      :color="
                        item.dam_tag_color && item.dam_tag_color != 'UNDEFINED'
                          ? 'oc_' +
                            item.dam_tag_color.toLowerCase() +
                            ' darken-1'
                          : 'grey darken-1'
                      "
                    >
                      <span style="color: white; font-weight: 500">{{
                        item.dam_tag_label
                      }}</span></v-chip
                    >
                    <span v-else>-</span>

                    <template v-slot:input>
                      <v-autocomplete
                        :key="item.id + 'dd'"
                        class="pt-2"
                        outlined
                        :items="dam_selection_array"
                        dense
                        v-if="edit_value"
                        v-model="edit_value.dam"
                        label="Dam"
                        :return-object="true"
                        @focus="focus_animal_select('dam', item.dob)"
                        @update:search-input="handleInputDam"
                      >
                        <template v-slot:item="{ item }">
                          <v-chip
                            style="
                              color: white;
                              font-weight: 500;
                              margin-bottom: -5px;
                            "
                            small
                            :color="
                              item.tag_color
                                ? 'oc_' +
                                  item.tag_color.toLowerCase() +
                                  ' darken-1'
                                : 'grey darken-1'
                            "
                            >{{ item.text }}</v-chip
                          >
                        </template>
                      </v-autocomplete>

                      <v-autocomplete
                        :key="item.id + 'ds'"
                        class="pt-2"
                        outlined
                        dense
                        v-if="edit_value"
                        v-model="edit_value.sire"
                        label="Sire"
                        :return-object="true"
                        :items="sire_selection_array"
                        @focus="focus_animal_select('sire', item.dob)"
                        @update:search-input="handleInputSire"
                      >
                        <template v-slot:item="{ item }">
                          <v-chip
                            style="
                              color: white;
                              font-weight: 500;
                              margin-bottom: -5px;
                            "
                            small
                            :color="
                              item.tag_color
                                ? 'oc_' +
                                  item.tag_color.toLowerCase() +
                                  ' darken-1'
                                : 'grey darken-1'
                            "
                            >{{ item.text }}</v-chip
                          >
                        </template>
                      </v-autocomplete>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.sire_tag_label`]="{ item, index }">
                  <v-edit-dialog
                    :key="item.id"
                    @save="
                      save_record(item.id, 'lineage', index, item.customer_id)
                    "
                    @cancel="cancel_edit"
                    large
                    @open="
                      open_field(
                        {
                          dam: { value: item.dam_id, text: item.dam_tag_label },
                          sire: {
                            value: item.sire_id,
                            text: item.sire_tag_label,
                          },
                        },
                        'lineage',
                        item.dob
                      )
                    "
                    @close="close_edit"
                  >
                    <v-img
                      v-if="show_heads && item.sire_id"
                      height="76px"
                      class="ma-1"
                      style="border-radius: 12px"
                      aspect-ratio="1"
                      :src="
                        item.sire_image_id
                          ? cropped_url(
                              item.sire_image_id,
                              item.sire_image_timestamp,
                              item.customer_id,
                              'head'
                            )
                          : placeholder_img_sire
                      "
                    >
                      <v-overlay
                        opacity="0"
                        absolute
                        style="
                          justify-content: start;
                          align-items: start;
                          pointer-events: none;
                        "
                      >
                        <v-chip
                          style="
                            height: 24px;
                            margin-bottom: -4px;
                            margin-top: -5px;
                            padding-top: 2px;
                            padding-left: 5px;
                            padding-right: 5px;
                            border-radius: 9px;
                          "
                          v-if="item.sire_tag_label"
                          :color="
                            item.sire_tag_color &&
                            item.sire_tag_color != 'UNDEFINED'
                              ? 'oc_' +
                                item.sire_tag_color.toLowerCase() +
                                ' darken-1'
                              : 'grey darken-1'
                          "
                        >
                          <span
                            style="
                              color: white;
                              font-weight: 500;
                              font-size: 12px;
                            "
                            >{{ item.sire_tag_label }}</span
                          ></v-chip
                        >
                      </v-overlay>
                    </v-img>

                    <v-chip
                      style="
                        height: 24px;
                        margin-bottom: -2px;
                        margin-top: -3px;
                        padding-top: 2px;
                      "
                      v-else-if="item.sire_tag_label"
                      :color="
                        item.sire_tag_color &&
                        item.sire_tag_color != 'UNDEFINED'
                          ? 'oc_' +
                            item.sire_tag_color.toLowerCase() +
                            ' darken-1'
                          : 'grey darken-1'
                      "
                    >
                      <span style="color: white; font-weight: 500">{{
                        item.sire_tag_label
                      }}</span></v-chip
                    >
                    <span v-else>-</span>

                    <template v-slot:input>
                      <v-autocomplete
                        :key="item.id + 'sd'"
                        class="pt-2"
                        outlined
                        :items="dam_selection_array"
                        dense
                        v-if="edit_value"
                        v-model="edit_value.dam"
                        label="Dam"
                        :return-object="true"
                        @focus="focus_animal_select('dam', item.dob)"
                        @update:search-input="handleInputDam"
                      >
                        <template v-slot:item="{ item }">
                          <v-chip
                            style="
                              color: white;
                              font-weight: 500;
                              margin-bottom: -5px;
                            "
                            small
                            :color="
                              item.tag_color
                                ? 'oc_' +
                                  item.tag_color.toLowerCase() +
                                  ' darken-1'
                                : 'grey darken-1'
                            "
                            >{{ item.text }}</v-chip
                          >
                        </template>
                      </v-autocomplete>

                      <v-autocomplete
                        :key="item.id + 'ss'"
                        class="pt-2"
                        outlined
                        dense
                        v-if="edit_value"
                        v-model="edit_value.sire"
                        label="Sire"
                        :return-object="true"
                        :items="sire_selection_array"
                        @focus="focus_animal_select('sire', item.dob)"
                        @update:search-input="handleInputSire"
                      >
                        <template v-slot:item="{ item }">
                          <v-chip
                            style="
                              color: white;
                              font-weight: 500;
                              margin-bottom: -5px;
                            "
                            small
                            :color="
                              item.tag_color
                                ? 'oc_' +
                                  item.tag_color.toLowerCase() +
                                  ' darken-1'
                                : 'grey darken-1'
                            "
                            >{{ item.text }}</v-chip
                          >
                        </template>
                      </v-autocomplete>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.dob`]="{ item, index }">
                  <v-edit-dialog
                    @close="close_edit"
                    @save="save_record(item.id, 'dob', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.dob, 'date')"
                  >
                    {{ item.dob ? item.dob : "-" }}
                    <template v-slot:input>
                      <v-date-picker
                        v-if="validateDateEmpty(edit_value) == true"
                        v-model="edit_value"
                        flat
                        no-title
                        event-color="#ff9900"
                        reactive
                      ></v-date-picker>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.sex`]="{ item, index }">
                  <v-edit-dialog
                    @close="close_edit"
                    @save="save_record(item.id, 'sex', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.sex)"
                  >
                    {{ capitalizeFirstLetter(item.sex) }}
                    <template v-slot:input>
                      <v-select
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        :items="[
                          { value: 'MALE', text: 'Male' },
                          { value: 'FEMALE', text: 'Female' },
                        ]"
                        label="Sex"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.classification`]="{ item, index }">
                  <v-edit-dialog
                    @close="close_edit"
                    @save="save_record(item.id, 'classification', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.classification)"
                  >
                    {{ capitalizeFirstLetter(item.classification) }}
                    <template v-slot:input>
                      <v-select
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        :items="enums.sex_classification"
                        label="Classification"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.color`]="{ item, index }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'color', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.color)"
                    @close="close_edit"
                  >
                    <span v-if="item.color">{{ item.color }}</span>
                    <span v-else>-</span>
                    <template v-slot:input>
                      <v-text-field
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        label="Color"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.status`]="{ item, index }">
                  <v-edit-dialog
                    @close="close_edit"
                    @save="save_record(item.id, 'status', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.status)"
                  >
                    {{ capitalizeFirstLetter(item.status) }}
                    <template v-slot:input>
                      <v-select
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        :items="enums.status"
                        label="Status"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.purebred`]="{ item, index }">
                  <div :class="item.pedigree ? 'disabled_edit_dialog' : ''">
                    <v-edit-dialog
                      @save="
                        save_record(
                          item.id,
                          'pedigree',
                          index,
                          item.customer_id
                        )
                      "
                      @cancel="cancel_edit"
                      large
                      @open="open_field({})"
                      @close="close_edit"
                    >
                      <v-checkbox
                        :input-value="item.pedigree ? true : false"
                        :readonly="true"
                        color="primary"
                      ></v-checkbox>
                      <template v-slot:input>
                        <v-text-field
                          v-if="edit_value"
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value.registered_name"
                          label="Registration Name*"
                          hide-details
                        ></v-text-field>
                        <v-text-field
                          v-if="edit_value"
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value.registration_association"
                          label="Registration Association*"
                          hide-details
                        ></v-text-field>
                        <v-text-field
                          v-if="edit_value"
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value.registration_number"
                          label="Registration Number*"
                          hide-details
                        ></v-text-field>
                        <v-text-field
                          v-if="edit_value"
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value.tattoo"
                          label="Tattoo*"
                          hide-details
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>
                <template v-slot:[`item.pedigree.tattoo`]="{ item, index }">
                  <div
                    :class="
                      item.pedigree
                        ? 'enabled_edit_dialog'
                        : 'disabled_edit_dialog'
                    "
                  >
                    <v-edit-dialog
                      @save="
                        save_record(
                          item.id,
                          'pedigree.tattoo',
                          index,
                          item.customer_id
                        )
                      "
                      @cancel="cancel_edit"
                      large
                      @open="open_field(item.pedigree.tattoo)"
                      @close="close_edit"
                    >
                      <span v-if="item.pedigree && item.pedigree.tattoo">{{
                        item.pedigree.tattoo
                      }}</span>
                      <span v-else>-</span>
                      <template v-slot:input>
                        <v-text-field
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value"
                          label="Tattoo"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>

                <template
                  v-slot:[`item.pedigree.registered_name`]="{ item, index }"
                  ><div
                    :class="
                      item.pedigree
                        ? 'enabled_edit_dialog'
                        : 'disabled_edit_dialog'
                    "
                  >
                    <v-edit-dialog
                      @save="
                        save_record(
                          item.id,
                          'pedigree.registered_name',
                          index,
                          item.customer_id
                        )
                      "
                      @cancel="cancel_edit"
                      large
                      @open="open_field(item.pedigree.registered_name)"
                      @close="close_edit"
                    >
                      <span
                        v-if="item.pedigree && item.pedigree.registered_name"
                        >{{ item.pedigree.registered_name }}</span
                      >
                      <span v-else>-</span>
                      <template v-slot:input>
                        <v-text-field
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value"
                          label="Registered Name"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>
                <template
                  v-slot:[`item.pedigree.registration_number`]="{ item, index }"
                  ><div
                    :class="
                      item.pedigree
                        ? 'enabled_edit_dialog'
                        : 'disabled_edit_dialog'
                    "
                  >
                    <v-edit-dialog
                      @save="
                        save_record(
                          item.id,
                          'pedigree.registration_number',
                          index,
                          item.customer_id
                        )
                      "
                      @cancel="cancel_edit"
                      large
                      @open="open_field(item.pedigree.registration_number)"
                      @close="close_edit"
                    >
                      <span
                        v-if="
                          item.pedigree && item.pedigree.registration_number
                        "
                        >{{ item.pedigree.registration_number }}</span
                      >
                      <span v-else>-</span>
                      <template v-slot:input>
                        <v-text-field
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value"
                          label="Registration Number"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>
                <template
                  v-slot:[`item.pedigree.registration_association`]="{
                    item,
                    index,
                  }"
                  ><div
                    :class="
                      item.pedigree
                        ? 'enabled_edit_dialog'
                        : 'disabled_edit_dialog'
                    "
                  >
                    <v-edit-dialog
                      @save="
                        save_record(
                          item.id,
                          'pedigree.registration_association',
                          index,
                          item.customer_id
                        )
                      "
                      @cancel="cancel_edit"
                      large
                      @open="open_field(item.pedigree.registration_association)"
                      @close="close_edit"
                    >
                      <span
                        v-if="
                          item.pedigree &&
                          item.pedigree.registration_association
                        "
                        >{{ item.pedigree.registration_association }}</span
                      >
                      <span v-else>-</span>
                      <template v-slot:input>
                        <v-text-field
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value"
                          label="Registration Association"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>
                <template
                  v-slot:[`item.pedigree.registration_date`]="{ item, index }"
                >
                  <div
                    :class="
                      item.pedigree
                        ? 'enabled_edit_dialog'
                        : 'disabled_edit_dialog'
                    "
                  >
                    <v-edit-dialog
                      @save="
                        save_record(
                          item.id,
                          'pedigree.registration_date',
                          index,
                          item.customer_id
                        )
                      "
                      @cancel="cancel_edit"
                      large
                      @open="
                        open_field(
                          item.pedigree ? item.pedigree.registration_date : '',
                          'registration_date',
                          index,
                          item.customer_id
                        )
                      "
                      @close="close_edit"
                    >
                      <span
                        v-if="item.pedigree && item.pedigree.registration_date"
                        >{{ item.pedigree.registration_date }}</span
                      >
                      <span v-else>-</span>
                      <template v-slot:input>
                        <v-date-picker
                          v-if="validateDateEmpty(edit_value) == true"
                          v-model="edit_value"
                          flat
                          no-title
                          class="mt-5"
                          event-color="#ff9900"
                          reactive
                        ></v-date-picker>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>
                <template v-slot:[`item.site_doa`]="{ item, index }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'site_doa', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.site_doa, 'date')"
                    @close="close_edit"
                  >
                    <span v-if="item.site_doa">{{ item.site_doa }}</span>
                    <span v-else>-</span>
                    <template v-slot:input>
                      <v-date-picker
                        v-if="validateDateEmpty(edit_value) == true"
                        v-model="edit_value"
                        flat
                        no-title
                        event-color="#ff9900"
                        reactive
                      ></v-date-picker>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.purpose`]="{ item, index }">
                  <v-edit-dialog
                    @close="close_edit"
                    @save="save_record(item.id, 'purpose', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.purpose)"
                  >
                    {{ capitalizeFirstLetter(item.purpose) }}
                    <template v-slot:input>
                      <v-select
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        :items="enums.animal_purpose"
                        label="Purpose"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template
                  v-slot:[`item.production_history_type`]="{ item, index }"
                >
                  <v-edit-dialog
                    @close="close_edit"
                    @save="
                      save_record(
                        item.id,
                        'production_history_type',
                        index,
                        item.customer_id
                      )
                    "
                    @cancel="cancel_edit"
                    large
                    @open="
                      open_field({
                        type: item.production_history_type,
                        start_datetime: item.production_history_start_datetime,
                        create_new: item.production_history_start_datetime
                          ? false
                          : true,
                      })
                    "
                  >
                    {{ capitalizeFirstLetter(item.production_history_type) }}
                    <template v-slot:input>
                      <v-select
                        v-if="edit_value"
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value.type"
                        :items="enums.production_type"
                        hide-details
                        label="Production Type"
                      ></v-select>
                      <v-checkbox
                        v-if="edit_value"
                        :readonly="!item.production_history_start_datetime"
                        v-model="edit_value.create_new"
                        label="Create new?"
                        hide-details
                      ></v-checkbox>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.ownership_status`]="{ item, index }">
                  <v-edit-dialog
                    @close="close_edit"
                    @save="save_record(item.id, 'ownership_status', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.ownership_status)"
                  >
                    {{ capitalizeFirstLetter(item.ownership_status) }}
                    <template v-slot:input>
                      <v-select
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        :items="enums.ownership_status"
                        label="Ownership"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.birth_ease`]="{ item, index }">
                  <v-edit-dialog
                    @close="close_edit"
                    @save="save_record(item.id, 'birth_ease', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.birth_ease)"
                  >
                    {{
                      item.birth_ease
                        ? capitalizeFirstLetter(birth_type[item.birth_ease])
                        : "-"
                    }}
                    <template v-slot:input>
                      <v-select
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        :items="enums.birth_ease"
                        label="Birth Type"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.nurse_info`]="{ item, index }">
                  <v-edit-dialog
                    @close="close_edit"
                    @save="save_record(item.id, 'nurse_info', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.nurse_info)"
                  >
                    {{
                      capitalizeFirstLetter(item.nurse_info).replaceAll(
                        "_",
                        " "
                      )
                    }}
                    <template v-slot:input>
                      <v-select
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        :items="enums.nurse_info"
                        label="Nursing Info"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.pregnant`]="{ item, index }">
                  <v-edit-dialog
                    @close="close_edit"
                    @save="save_record(item.id, 'pregnant', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.pregnant)"
                  >
                    {{
                      item.sex == "FEMALE"
                        ? capitalizeFirstLetter(item.pregnant)
                        : "-"
                    }}
                    <template v-slot:input>
                      <v-select
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        :items="[
                          { value: 'YES', text: 'Yes' },
                          { value: 'NO', text: 'No' },
                          { value: 'UNDEFINED', text: 'Undefined' },
                        ]"
                        label="Pregnancy Status"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.dam_status`]="{ item, index }">
                  <v-edit-dialog
                    style="z-index: 12"
                    @close="close_edit"
                    @save="save_record(item.id, 'dam_status', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.dam_status)"
                  >
                    {{
                      capitalizeFirstLetter(item.dam_status).replaceAll(
                        "_",
                        " "
                      )
                    }}
                    <template v-slot:input>
                      <v-select
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        :items="enums.dam_status"
                        label="Dam Status"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.cod`]="{ item, index }">
                  <v-edit-dialog
                    @close="close_edit"
                    @save="save_record(item.id, 'cod', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.cod)"
                  >
                    {{ item.cod ? item.cod : "-" }}
                    <template v-slot:input>
                      <v-select
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        :items="
                          item.classification == 'CALF'
                            ? [
                                'Abort/Prematurity',
                                'Deficiency',
                                'Disease/Infection',
                                'External Injuries',
                                'Internal Injuries',
                                'Malnutrition',
                                'Wildlife',
                              ]
                            : [
                                'Acts of God',
                                'Broken Bone',
                                'Complications',
                                'Disease',
                                'Infection',
                                'Natural Causes',
                                'Parasite',
                                'Pneumonia',
                                'Other',
                              ]
                        "
                        label="Cause of Death"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.breeding_history`]="{ item, index }">
                  <v-edit-dialog
                    v-if="item.classification == 'COW'"
                    @close="close_edit"
                    @save="save_record(item.id, 'breeding_history', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.breeding_history)"
                  >
                    {{
                      item.breeding_history
                        ? capitalizeFirstLetter(
                            item.breeding_history[0].type
                          ).replaceAll("_", " ")
                        : "-"
                    }}
                    <template v-slot:input>
                      <v-select
                        v-model="edit_value"
                        :items="[
                          {
                            value: 'ARTIFICIAL_INSEMINATION',
                            text: 'Artificial Insemination',
                          },
                          { value: 'ET_DONOR', text: 'Embry Transfer Donor' },

                          {
                            value: 'ET_RECIPIENT',
                            text: 'Embry Transfer Recipient',
                          },
                          {
                            value: 'NATURAL',
                            text: 'Natural',
                          },
                          { value: 'UNDEFINED', text: 'Undefined' },
                        ]"
                        label="Breedint Type"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                  <span v-else> - </span>
                </template>
                <template v-slot:[`item.herd_name`]="{ item }">
                  {{ item.herd_name ? item.herd_name : "-" }}
                </template>
                <template
                  v-slot:[`item.data-table-expand`]="{ item, isExpanded }"
                >
                  <v-icon
                    @click.stop="handleExpansion(item, isExpanded)"
                    dense
                    :class="'mr-5'"
                    :color="isExpanded ? 'primary' : 'gray'"
                    >{{
                      isExpanded ? "mdi-note-off-outline" : "mdi-note-outline"
                    }}</v-icon
                  >
                  <v-icon
                    style="z-index: 10"
                    @click.stop="
                      delete_animal_id = item.id;
                      delete_confirm = '';
                      delete_dialog = true;
                    "
                    :disabled="allowed_operations != 'WRITE'"
                  >
                    mdi-delete-outline
                  </v-icon>
                </template>

                <template v-slot:[`item.measurement_height`]="{ item, index }">
                  <v-edit-dialog
                    @close="close_edit"
                    @save="save_record(item.id, 'measurement_height', index)"
                    @cancel="cancel_edit"
                    large
                    @open="
                      open_field({
                        id: item.measurement_height_id,
                        value: unit_system_multiplier
                          ? item.measurement_height *
                            unit_system_multiplier.length
                          : item.measurement_height,
                        unit: 'CM',
                        animal_id: item.id,
                        type: 'HEIGHT',
                        customer_id: item.customer_id,
                        examiner: $store.getters.getUser.username,
                        create_new: item.measurement_height_id ? false : true,
                      })
                    "
                  >
                    <span v-if="item.measurement_height_id">
                      {{
                        unit_system_multiplier
                          ? item.measurement_height *
                            unit_system_multiplier.length
                          : item.measurement_height
                      }}
                    </span>
                    <span v-else>-</span>

                    <template v-slot:input>
                      <v-text-field
                        v-if="edit_value"
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value.value"
                        label="Value"
                        hide-details
                      ></v-text-field>
                      <v-checkbox
                        v-if="edit_value"
                        :readonly="!item.measurement_height_id"
                        v-model="edit_value.create_new"
                        label="Create new?"
                        hide-details
                      ></v-checkbox>
                    </template>
                  </v-edit-dialog>
                </template>

                <template
                  v-slot:[`item.measurement_weight_birth`]="{ item, index }"
                >
                  <v-edit-dialog
                    @close="close_edit"
                    @save="
                      save_record(item.id, 'measurement_weight_birth', index)
                    "
                    @cancel="cancel_edit"
                    large
                    @open="
                      open_field({
                        id: item.measurement_weight_birth_id,
                        value: unit_system_multiplier
                          ? item.measurement_weight_birth *
                            unit_system_multiplier.weight
                          : item.measurement_weight_birth,
                        unit: 'KG',
                        animal_id: item.id,
                        type: 'WEIGHT_BIRTH',
                        customer_id: item.customer_id,
                        examiner: $store.getters.getUser.username,
                      })
                    "
                  >
                    <span v-if="item.measurement_weight_birth_id">
                      {{
                        unit_system_multiplier
                          ? item.measurement_weight_birth *
                            unit_system_multiplier.weight
                          : item.measurement_weight_birth
                      }}
                    </span>
                    <span v-else>-</span>

                    <template v-slot:input>
                      <v-text-field
                        v-if="edit_value"
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value.value"
                        label="Value"
                        hide-details
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template
                  v-slot:[`item.measurement_weight_weaning`]="{ item, index }"
                >
                  <v-edit-dialog
                    @close="close_edit"
                    @save="
                      save_record(item.id, 'measurement_weight_weaning', index)
                    "
                    @cancel="cancel_edit"
                    large
                    @open="
                      open_field({
                        id: item.measurement_weight_weaning_id,
                        value: unit_system_multiplier
                          ? item.measurement_weight_weaning *
                            unit_system_multiplier.weight
                          : item.measurement_weight_weaning,
                        unit: 'KG',
                        animal_id: item.id,
                        type: 'WEIGHT_WEANING',
                        customer_id: item.customer_id,
                        examiner: $store.getters.getUser.username,
                      })
                    "
                  >
                    <span v-if="item.measurement_weight_weaning_id">
                      {{
                        unit_system_multiplier
                          ? item.measurement_weight_weaning *
                            unit_system_multiplier.weight
                          : item.measurement_weight_weaning
                      }}
                    </span>
                    <span v-else>-</span>

                    <template v-slot:input>
                      <v-text-field
                        v-if="edit_value"
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value.value"
                        label="Value"
                        hide-details
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template
                  v-slot:[`item.measurement_weight_yearling`]="{ item, index }"
                >
                  <v-edit-dialog
                    @close="close_edit"
                    @save="
                      save_record(item.id, 'measurement_weight_yearling', index)
                    "
                    @cancel="cancel_edit"
                    large
                    @open="
                      open_field({
                        id: item.measurement_weight_yearling_id,
                        value: unit_system_multiplier
                          ? item.measurement_weight_yearling *
                            unit_system_multiplier.weight
                          : item.measurement_weight_yearling,
                        unit: 'KG',
                        animal_id: item.id,
                        type: 'WEIGHT_YEARLING',
                        customer_id: item.customer_id,
                        examiner: $store.getters.getUser.username,
                      })
                    "
                  >
                    <span v-if="item.measurement_weight_yearling_id">
                      {{
                        unit_system_multiplier
                          ? item.measurement_weight_yearling *
                            unit_system_multiplier.weight
                          : item.measurement_weight_yearling
                      }}
                    </span>
                    <span v-else>-</span>
                    <template v-slot:input>
                      <v-text-field
                        v-if="edit_value"
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value.value"
                        label="Value"
                        hide-details
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template
                  v-slot:[`item.measurement_weight_mature`]="{ item, index }"
                >
                  <v-edit-dialog
                    @close="close_edit"
                    @save="
                      save_record(item.id, 'measurement_weight_mature', index)
                    "
                    @cancel="cancel_edit"
                    large
                    @open="
                      open_field({
                        id: item.measurement_weight_mature_id,
                        value: unit_system_multiplier
                          ? item.measurement_weight_mature *
                            unit_system_multiplier.weight
                          : item.measurement_weight_mature,
                        unit: 'KG',
                        animal_id: item.id,
                        type: 'WEIGHT_MATURE',
                        customer_id: item.customer_id,
                        examiner: $store.getters.getUser.username,
                        create_new: item.measurement_weight_mature_id
                          ? false
                          : true,
                      })
                    "
                  >
                    <span v-if="item.measurement_weight_mature_id">
                      {{
                        unit_system_multiplier
                          ? item.measurement_weight_mature *
                            unit_system_multiplier.weight
                          : item.measurement_weight_mature
                      }}
                    </span>
                    <span v-else>-</span>

                    <template v-slot:input>
                      <v-text-field
                        v-if="edit_value"
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value.value"
                        label="Value"
                        hide-details
                      ></v-text-field>
                      <v-checkbox
                        v-if="edit_value"
                        :readonly="!item.measurement_weight_mature_id"
                        v-model="edit_value.create_new"
                        label="Create new?"
                        hide-details
                      ></v-checkbox>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.add_phenotype`]="{ item, index }">
                  <div>
                    <v-edit-dialog
                      @save="save_record(item.id, 'phenotype', index)"
                      @cancel="cancel_edit"
                      large
                      @open="
                        open_field({
                          id: item.measurement_weight_mature_id,
                          animal_id: item.id,
                          customer_id: item.customer_id,
                          examiner: $store.getters.getUser.username,
                        })
                      "
                      @close="close_edit"
                    >
                      <v-icon color="primary">mdi-plus-box-outline</v-icon>
                      <template v-slot:input>
                        <v-text-field
                          v-if="edit_value"
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value.bcs"
                          label="BCS"
                          hide-details
                        ></v-text-field>
                        <v-text-field
                          v-if="edit_value"
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value.claw"
                          label="Claw"
                          hide-details
                        ></v-text-field>
                        <v-text-field
                          v-if="edit_value"
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value.hoof"
                          label="Hoof"
                          hide-details
                        ></v-text-field>
                        <v-text-field
                          v-if="edit_value"
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value.leg"
                          label="Leg"
                          hide-details
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>

                <template v-slot:[`item.phenotype_bcs`]="{ item, index }">
                  <div
                    :class="
                      item.phenotype_id
                        ? 'enabled_edit_dialog'
                        : 'disabled_edit_dialog'
                    "
                  >
                    <v-edit-dialog
                      @close="close_edit"
                      @save="save_record(item.id, 'phenotype_bcs', index)"
                      @cancel="cancel_edit"
                      large
                      @open="
                        open_field({
                          id: item.phenotype_id,
                          animal_id: item.id,
                          customer_id: item.customer_id,
                          examiner: $store.getters.getUser.username,
                          bcs: item.phenotype_bcs,
                        })
                      "
                    >
                      {{ item.phenotype_bcs ? item.phenotype_bcs : "-" }}
                      <template v-slot:input>
                        <v-text-field
                          v-if="edit_value"
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value.bcs"
                          label="Value"
                          hide-details
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>
                <template v-slot:[`item.phenotype_claw`]="{ item, index }">
                  <div
                    :class="
                      item.phenotype_id
                        ? 'enabled_edit_dialog'
                        : 'disabled_edit_dialog'
                    "
                  >
                    <v-edit-dialog
                      @close="close_edit"
                      @save="save_record(item.id, 'phenotype_claw', index)"
                      @cancel="cancel_edit"
                      large
                      @open="
                        open_field({
                          id: item.phenotype_id,
                          animal_id: item.id,
                          customer_id: item.customer_id,
                          examiner: $store.getters.getUser.username,
                          claw: item.phenotype_claw,
                        })
                      "
                    >
                      {{ item.phenotype_claw ? item.phenotype_claw : "-" }}
                      <template v-slot:input>
                        <v-text-field
                          v-if="edit_value"
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value.claw"
                          label="Value"
                          hide-details
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>
                <template v-slot:[`item.phenotype_hoof`]="{ item, index }">
                  <div
                    :class="
                      item.phenotype_id
                        ? 'enabled_edit_dialog'
                        : 'disabled_edit_dialog'
                    "
                  >
                    <v-edit-dialog
                      @close="close_edit"
                      @save="save_record(item.id, 'phenotype_hoof', index)"
                      @cancel="cancel_edit"
                      large
                      @open="
                        open_field({
                          id: item.phenotype_id,
                          animal_id: item.id,
                          customer_id: item.customer_id,
                          examiner: $store.getters.getUser.username,
                          hoof: item.phenotype_hoof,
                        })
                      "
                    >
                      {{ item.phenotype_hoof ? item.phenotype_hoof : "-" }}
                      <template v-slot:input>
                        <v-text-field
                          v-if="edit_value"
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value.hoof"
                          label="Value"
                          hide-details
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>
                <template v-slot:[`item.phenotype_leg`]="{ item, index }">
                  <div
                    :class="
                      item.phenotype_id
                        ? 'enabled_edit_dialog'
                        : 'disabled_edit_dialog'
                    "
                  >
                    <v-edit-dialog
                      @close="close_edit"
                      @save="save_record(item.id, 'phenotype_leg', index)"
                      @cancel="cancel_edit"
                      large
                      @open="
                        open_field({
                          id: item.phenotype_id,
                          animal_id: item.id,
                          customer_id: item.customer_id,
                          examiner: $store.getters.getUser.username,
                          leg: item.phenotype_leg,
                        })
                      "
                    >
                      {{ item.phenotype_leg ? item.phenotype_leg : "-" }}
                      <template v-slot:input>
                        <v-text-field
                          v-if="edit_value"
                          class="mt-4"
                          outlined
                          dense
                          v-model="edit_value.leg"
                          label="Value"
                          hide-details
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>
                <template v-slot:[`item.phenotype_date`]="{ item, index }">
                  <div
                    :class="
                      item.phenotype_id
                        ? 'enabled_edit_dialog'
                        : 'disabled_edit_dialog'
                    "
                  >
                    <v-edit-dialog
                      @save="
                        save_record(
                          item.id,
                          'phenotype_date',
                          index,
                          item.customer_id
                        )
                      "
                      @cancel="cancel_edit"
                      large
                      @open="
                        open_field({
                          id: item.phenotype_id,
                          animal_id: item.id,
                          customer_id: item.customer_id,
                          examiner: $store.getters.getUser.username,
                          date: item.phenotype_date,
                        })
                      "
                      @close="close_edit"
                    >
                      <span v-if="item.phenotype_date">{{
                        item.phenotype_date
                      }}</span>
                      <span v-else>-</span>
                      <template v-slot:input>
                        <v-date-picker
                          v-if="
                            edit_value &&
                            validateDateEmpty(edit_value.date) == true
                          "
                          v-model="edit_value.date"
                          flat
                          no-title
                          class="mt-5"
                          event-color="#ff9900"
                          reactive
                        ></v-date-picker>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>
              </v-data-table>
            </v-card-text> </v-card
        ></v-col>
      </v-row>

      <v-dialog v-model="expand_dialog" max-width="800px">
        <animalCard
          v-if="selected_animal_id"
          :animal_prop="selected_animal_id"
          :animal_index_pos="selected_animal_index_pos"
          :tab_index="0"
          :index="selected_animal_index"
        />
      </v-dialog>
    </v-container>
    <v-dialog v-model="note_dialog" max-width="800px" min-width="760px">
      <v-card class="pb-3">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Add Note</h2>
              <v-spacer></v-spacer>
              <v-btn icon @click="note_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >

          <v-text-field
            v-model="note_text"
            label="Note"
            outlined
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="pr-6">
          <v-spacer></v-spacer>
          <v-btn @click="note_dialog = false"> Cancel </v-btn>
          <v-btn color="primary" @click="add_note"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="herd_dialog" max-width="560px" min-width="560px">
      <v-card class="pb-3">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Add Herd</h2>
              <v-spacer></v-spacer>
              <v-btn icon @click="herd_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="create_herd_object.name"
                label="Name"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="const_text_field_color">
              <v-autocomplete
                outlined
                dense
                v-model="create_herd_object.tag_color"
                label="Tag Color"
                :items="enums.tag_color"
                hide-no-data
              ></v-autocomplete
            ></v-col> </v-row
        ></v-card-text>

        <v-card-actions class="pr-6">
          <v-spacer></v-spacer>
          <v-btn @click="herd_dialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            @click="add_herd"
            :disabled="!create_herd_object.name"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="edit_herd_dialog" max-width="560px" min-width="560px">
      <v-card class="pb-3">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Edit Herd</h2>
              <v-spacer></v-spacer>
              <v-btn icon @click="edit_herd_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="herd_edited.name"
                label="Name*"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" class="const_text_field_color">
              <v-autocomplete
                outlined
                dense
                v-model="herd_edited.tag_color"
                label="Tag Color"
                :items="enums.tag_color"
                hide-no-data
              ></v-autocomplete
            ></v-col> </v-row
        ></v-card-text>

        <v-card-actions class="pr-6">
          <v-icon @click="delete_herd_dialog = true">mdi-delete</v-icon>
          <v-spacer></v-spacer>
          <v-btn @click="edit_herd_dialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            @click="update_herd"
            :disabled="!herd_edited.name"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="delete_herd_dialog" max-width="400px" min-width="400px">
      <v-card class="pb-3">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Delete Herd</h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="delete_herd_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >
        </v-card-text>

        <v-card-actions class="pr-6">
          <v-spacer></v-spacer>
          <v-btn @click="delete_herd_dialog = false"> Cancel </v-btn>
          <v-btn color="oc_red white--text" @click="delete_herd">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_dialog" max-width="400px" min-width="400px">
      <v-card class="pb-3">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Delete Animal Entry?</h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="delete_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >
          <v-row>
            <v-col cols="12" class="my-0 py-0"
              >Type <i> yes </i> to confirm</v-col
            >
            <v-col cols="12" class="my-0"
              ><v-text-field
                outlined
                hide-details
                dense
                v-model="delete_confirm"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pr-6">
          <v-spacer></v-spacer>
          <v-btn @click="delete_dialog = false"> Cancel </v-btn>
          <v-btn
            color="oc_red white--text"
            :disabled="delete_confirm != 'yes'"
            @click="delete_animal"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_note_dialog" max-width="400px" min-width="400px">
      <v-card class="pb-3">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Delete Note?</h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="delete_note_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >
        </v-card-text>

        <v-card-actions class="pr-6">
          <v-spacer></v-spacer>
          <v-btn @click="delete_note_dialog = false"> Cancel </v-btn>
          <v-btn color="oc_red white--text" @click="delete_note">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <events></events> -->
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import animalCard from "@/components/AnimalCard.vue";
import {
  validateDateEmpty,
  validateNotEmpty,
} from "@/utilities/ValidationRules";
import { eventBus } from "../main.js";
import moment from "moment";
import placeholder_img from "@/assets/cover_brown.png";
import placeholder_img_dam from "@/assets/cover_red.png";
import placeholder_img_sire from "@/assets/cover_green.png";

import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";

import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    navbar,
    sidenav,
    //events,
    animalCard,
  },
  props: ["animal_index"],
  data() {
    return {
      placeholder_img: placeholder_img,
      placeholder_img_dam: placeholder_img_dam,
      placeholder_img_sire: placeholder_img_sire,
      staging_data: [],
      production_data: [],
      loading: false,
      customer: "All",
      video_duration: null,
      video_url: null,

      editedIndex: 0,
      create_new: false,
      selected_table: 0,
      edit_value: null,
      expand_dialog: false,
      selected_animal_id: null,
      selected_animal_index_pos: null,
      selected_animal_index: null,
      editable: false,
      registration_date: "",
      edit_value_note: null,

      selected_animals: [],

      note_dialog: false,
      note_text: "",
      note_index: false,

      herd_dialog: false,
      selected_herd: false,
      create_herd_object: {},

      show_heads: true,

      delete_dialog: false,
      delete_animal_id: null,

      rules: {
        rfid: (v) =>
          v != undefined ? v.length == 15 : false || "Nickname is required.",
      },

      birth_type: {
        UNASISSTED: "Unasissted Delivery",
        EASY_PULL: "Easy Pull",
        HARD_PULL: "Hard Pull",
        MALPRESENTATION: "Malpresentation",
        SURGICAL: "Surgical",
        UNDEFINED: "Undefined",
      },

      sex_filter: "All",
      classification_filter: "All",
      status_filter: "All",
      purpose_filter: "All",
      production_filter: "All",
      ownership_filter: "All",
      birth_filter: "All",
      nursing_filter: "All",
      death_filter: "All",
      tag_color_filter: "All",

      edit_herd_dialog: false,
      herd_edited: {},
      delete_herd_dialog: false,

      hovered_chip: null,

      delete_note_dialog: false,
      delete_note_id: null,

      delete_confirm: "",

      options: {},
      sort_by: "tag_label",
      page: 1,
      sort_desc: false,
      search: "",

      selection_query_params: {
        classification: false,
        dob: false,
        search: null,
        search_dam: null,
        search_sire: null,
        active: false,
      },

      selection_type: false,

      animals: [],

      first_time: true,

      expanded: [],
    };
  },
  methods: {
    validateNotEmpty,
    async handleInputDam(searchQuery) {
      if (!searchQuery) {
        return;
      }
      if (this.selection_type.classification == "COW") {
        this.selection_query_params = {
          classification: this.selection_type.classification,
          dob: this.selection_type.dob,
          search: null,
          search_dam: searchQuery,
          search_sire: null,
          active: this.selection_type.type,
        };
      }
    },
    async handleInputSire(searchQuery) {
      if (!searchQuery) {
        return;
      }
      if (this.selection_type.classification == "BULL") {
        this.selection_query_params = {
          classification: this.selection_type.classification,
          dob: this.selection_type.dob,
          search: null,
          search_dam: null,
          search_sire: searchQuery,
          active: this.selection_type.type,
        };
      }
    },
    handleExpansion(item, state) {
      this.expanded = [];
      if (!state) {
        this.expanded = [item];
      }
    },
    edit_herd(herd) {
      this.herd_edited = {
        name: herd.text,
        id: herd.value,
        tag_color: herd.herd_obj.tag_color,
      };
      this.edit_herd_dialog = true;
    },
    cropped_url(id, timestamp, customer_id, type) {
      let ymd = moment(timestamp).format("YYYY/MM/DD");
      let total_date = moment(timestamp).format("YYYY-MM-DD_HH-mm-ss");
      return `${process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE}${customer_id}/animals/images/${ymd}/${id}_${total_date}_${type}.jpg`;
    },

    async delete_animal() {
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_animal_by_pk,
            variables: {
              pk_columns: {
                id: this.delete_animal_id,
              },
              _set: { deleted_at: moment().format("YYYY-MM-DDTHH:mm:ss") },
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }

        this.delete_dialog = false;
        this.$store.dispatch("DDB_GET_ANIMALS", this.query_params).then(() => {
          this.animals = this.$store.getters.getAnimals;
        });
        eventBus.$emit("add_button_alert", {
          msg: "Successfully deleted the animal",
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: "Error deleting the animal",
          err: true,
        });
      }
    },
    async delete_note() {
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_user_note_by_pk,
            variables: {
              pk_columns: {
                id: this.delete_note_id.id,
              },
              _set: { deleted_at: moment().format("YYYY-MM-DDTHH:mm:ss") },
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        this.expanded[0].notes.splice(this.delete_note_id.index, 1);
        this.delete_note_dialog = false;
        eventBus.$emit("add_button_alert", {
          msg: "Successfully deleting the note",
          err: false,
        });
      } catch (err) {
        console.log(err);
        eventBus.$emit("add_button_alert", {
          msg: "Error deleting the note",
          err: true,
        });
      }
    },
    async delete_herd() {
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_herd_by_pk,
            variables: {
              pk_columns: {
                id: this.herd_edited.id,
              },
              _set: { deleted_at: moment().format("YYYY-MM-DDTHH:mm:ss") },
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }

        this.selected_herd = false;

        this.$store.dispatch("DDB_GET_HERDS");
        this.$store.dispatch("DDB_GET_ANIMALS", this.query_params).then(() => {
          this.animals = this.$store.getters.getAnimals;
        });

        this.edit_herd_dialog = false;
        this.delete_herd_dialog = false;

        eventBus.$emit("add_button_alert", {
          msg: `Successfully deleted the herd`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error deleting the herd`,
          err: true,
        });
      }
    },
    async update_herd() {
      try {
        let id = this.herd_edited.id;
        delete this.herd_edited.id;

        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_herd_by_pk,
            variables: {
              pk_columns: { id: id },
              _set: this.herd_edited,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }

        this.$store.dispatch("DDB_GET_HERDS");

        this.edit_herd_dialog = false;

        eventBus.$emit("add_button_alert", {
          msg: "Successfully updated the herd",
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: "Error updating the herd",
          err: true,
        });
      }
    },
    download_csv() {
      const csvString =
        "data:text/csv;charset=utf-8," +
        [
          [
            "Tag",
            "RFID",
            "Sex",
            "Classification",
            "Herd",
            "Birth Date",
            "Status",
            "Color",
            "Dam",
            "Sire",
            "Date of Arrival",
            "Purpose",
            "Production",
            "Ownership",
            "Birth Ease",
            "Nurse Info",
            "Cause of Death",
            "Dam Status",
          ],
          ...this.animals.map((item) => [
            item.tag_label,
            item.rfid_current,
            item.sex,
            item.classification,
            item.herd_id ? item.herd_name : "-",
            item.dob,
            item.status,
            item.color,
            item.dam_tag_id && item.dam_tag_label ? item.dam_tag_label : "-",
            item.sire_tag_id && item.sire_tag_label ? item.sire_tag_label : "-",
            item.site_doa ? item.site_doa : "-",
            item.purpose ? item.purpose : "-",
            item.production_history_type ? item.production_history_type : "-",
            item.ownership_status ? item.ownership_status : "-",
            item.birth_ease ? item.birth_ease : "-",
            item.nurse_info ? item.nurse_info : "-",
            item.cod ? item.cod : "-",

            item.dam_status ? item.dam_status : "-",
          ]),
        ]
          .map((e) => e.join(","))
          .join("\n");
      var encodedUri = encodeURI(csvString);
      window.open(encodedUri);
    },

    async add_herd() {
      try {
        this.create_herd_object.id = uuidv4();
        this.create_herd_object.created_date = moment().format("YYYY-MM-DD");
        this.create_herd_object.customer_id = this.$store.getters.getCustomerID;
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.insert_herd_one,
            variables: {
              object1: this.create_herd_object,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }

        this.herd_dialog = false;
        this.create_herd_object = {};
        this.$store.dispatch("DDB_GET_HERDS");

        eventBus.$emit("add_button_alert", {
          msg: "Successfully created the herd",
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: "Error creating the herd",
          err: true,
        });
      }
    },
    async add_note() {
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.insert_user_note_one,
            variables: {
              object1: {
                id: uuidv4(),
                customer_id: this.$store.getters.getCustomerID,
                author: this.$store.getters.getUser.username,
                datetime: moment().format("YYYY-MM-DDThh:mm:ss"),
                comment: this.note_text,
                animal_id: this.note_index.id,
              },
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        this.animals[this.animals.indexOf(this.note_index)].notes.unshift(
          response.data.data.insert_user_note_one
        );
        this.note_dialog = false;
        this.note_text = "";
        this.note_index = false;
        eventBus.$emit("add_button_alert", {
          msg: `Successfully created the note`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error creating the note`,
          err: true,
        });
      }
    },
    check_date_format(value) {
      return validateDateEmpty(value) === true || value == null;
    },
    date_null(date) {
      if (validateDateEmpty(this.create_item[date]) !== true) {
        this.create_item[date] = null;
      }
    },
    validateDateEmpty,
    select_animal(item) {
      if (
        (!this.editable && item && !this.delete_dialog) ||
        this.animal_inde != "all"
      ) {
        this.selected_animal_id = item;
        this.selected_animal_index = this.animals.indexOf(item);
        if (this.selected_animal_index == 0) {
          this.selected_animal_index_pos = "first";
        } else if (this.selected_animal_index == this.animals.length - 1) {
          this.selected_animal_index_pos = "last";
        } else {
          this.selected_animal_index_pos = "m";
        }

        this.expand_dialog = true;
      }
    },
    async assign_remove_herd(herd_id) {
      var animal_ids = this.selected_animals.map((obj) => obj.id);
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_animal,
            variables: {
              where: { id: { _in: animal_ids } },
              _set: { herd_id: herd_id },
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );

        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        this.selected_animals = [];
        this.$store.dispatch("DDB_GET_ANIMALS", this.query_params).then(() => {
          this.animals = this.$store.getters.getAnimals;
        });

        eventBus.$emit("add_button_alert", {
          msg: `Successfully assigned animals to herd`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error assigning animals to herd`,
          err: true,
        });
      }
    },
    async save_record(id, field, index, customer_id) {
      try {
        var set_params = {};
        if (field == "tag") {
          if (!this.edit_value.create_new) {
            set_params = {
              label: this.edit_value.tag_label,
              color: this.edit_value.tag_color,
            };
            let response = await axios.post(
              this.$store.getters.getHasuraParamsWrite.url,
              {
                query: hasura_mutations.update_tag_by_pk,
                variables: {
                  pk_columns: { id: this.edit_value.tag_id },
                  _set: set_params,
                },
              },
              {
                headers: this.$store.getters.getHasuraParamsWrite.headers,
              }
            );
            if (response.errors || response.data.errors) {
              throw new Error("GraphQL error: ");
            }
            this.animals[index].tag_label =
              response.data.data.update_tag_by_pk.label;
            this.animals[index].tag_color =
              response.data.data.update_tag_by_pk.label;
          } else {
            set_params = {
              label: this.edit_value.tag_label,
              color: this.edit_value.tag_color,
              customer_id: this.animals[index].customer_id,
              id: uuidv4(),
              date: moment().format("YYYY-MM-DDTHH:mm:ss"),
              animal_id: this.animals[index].id,
            };

            let response = await axios.post(
              this.$store.getters.getHasuraParamsWrite.url,
              {
                query: hasura_mutations.insert_tag_one,
                variables: {
                  object1: set_params,
                },
              },
              {
                headers: this.$store.getters.getHasuraParamsWrite.headers,
              }
            );
            if (response.errors || response.data.errors) {
              throw new Error("GraphQL error: ");
            }

            let animal_response = await axios.post(
              this.$store.getters.getHasuraParamsWrite.url,
              {
                query: hasura_mutations.update_animal_by_pk,
                variables: {
                  pk_columns: { id: id },
                  _set: { current_tag_id: set_params.id },
                },
              },
              {
                headers: this.$store.getters.getHasuraParamsWrite.headers,
              }
            );
            if (animal_response.errors || animal_response.data.errors) {
              throw new Error("GraphQL error: ");
            }
            this.animals[index].tag_label = this.edit_value.tag_label;
            this.animals[index].tag_color = this.edit_value.tag_color;
          }
        } else if (field == "lineage") {
          let update_columns = [];
          if (this.edit_value.dam && this.edit_value.dam.value) {
            set_params.dam_id = this.edit_value.dam.value;
            update_columns.push("dam_id");
          }
          if (this.edit_value.sire && this.edit_value.sire.value) {
            set_params.sire_id = this.edit_value.sire.value;
            update_columns.push("sire_id");
          }

          set_params.offspring_id = id;
          set_params.customer_id = customer_id;
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_lineage_one,
              variables: {
                object1: set_params,
                update_columns: update_columns,
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: ");
          }
          this.$store
            .dispatch("DDB_GET_ANIMALS", this.query_params)
            .then(() => {
              this.animals = this.$store.getters.getAnimals;
            });
        } else if (field.includes("pedigree")) {
          if (!field.includes(".")) {
            set_params.customer_id = customer_id;
            set_params.animal_id = id;
            for (const r in this.edit_value) {
              set_params[r] = this.edit_value[r];
            }

            set_params.registration_date = moment().format("YYYY-MM-DD");
          } else {
            set_params = { ...this.animals[index].pedigree };
            set_params[field.split(".")[1]] = this.edit_value;
          }
          set_params.customer_id = customer_id;
          set_params.animal_id = id;
          let update_column = [];
          if (field.split(".")[1]) {
            update_column.push(field.split(".")[1]);
          }
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_pedigree_one,
              variables: {
                object1: set_params,
                update_columns: update_column,
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          if (!field.includes(".")) {
            this.animals[index].pedigree = {};
            for (const r in response.data.data.insert_pedigree_one) {
              this.animals[index].pedigree[r] =
                response.data.data.insert_pedigree_one[r];
            }
          } else {
            this.animals[index].pedigree[field.split(".")[1]] =
              response.data.data.insert_pedigree_one[field.split(".")[1]];
          }
        } else if (field.includes("measurement")) {
          set_params = { ...this.edit_value };
          if (!this.edit_value.id || this.edit_value.create_new) {
            set_params.id = uuidv4();
          }
          set_params.date = moment().format("YYYY-MM-DDTHH:mm:ss");
          if (this.unit_system_multiplier) {
            if (set_params.unit == "KG") {
              set_params.value =
                set_params.value / this.unit_system_multiplier.weight;
            } else if (set_params.unit == "CM") {
              set_params.value =
                set_params.value / this.unit_system_multiplier.length;
            }
          }
          delete set_params.create_new;
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_measurement_one,
              variables: {
                object1: set_params,
                update_columns: ["value"],
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }

          this.animals[index][field] =
            response.data.data.insert_measurement_one.value;
          this.animals[index][field + "_id"] =
            response.data.data.insert_measurement_one.id;
        } else if (field.includes("phenotype")) {
          set_params = { ...this.edit_value };
          let phenotype_field = "date";
          if (!this.edit_value.id) {
            set_params.id = uuidv4();
            set_params.date = moment().format("YYYY-MM-DDTHH:mm:ss");
          } else {
            phenotype_field = field.split("_")[1];
            if (phenotype_field != "date") {
              set_params.date = this.animals[index].phenotype_date;
            }
            if (!set_params.date) {
              set_params.date = moment().format("YYYY-MM-DDTHH:mm:ss");
            }
            if (!phenotype_field) {
              phenotype_field = "date";
            }
          }

          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_phenotype_one,
              variables: {
                object1: set_params,
                update_columns: [phenotype_field],
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          this.animals[index]["phenotype_id"] =
            response.data.data.insert_phenotype_one.id;
          this.animals[index]["phenotype_bcs"] =
            response.data.data.insert_phenotype_one.bcs;
          this.animals[index]["phenotype_claw"] =
            response.data.data.insert_phenotype_one.claw;
          this.animals[index]["phenotype_hoof"] =
            response.data.data.insert_phenotype_one.hoof;
          this.animals[index]["phenotype_leg"] =
            response.data.data.insert_phenotype_one.leg;
          this.animals[index]["phenotype_date"] =
            response.data.data.insert_phenotype_one.date;
        } else if (field == "production_history_type") {
          if (this.edit_value.create_new == true) {
            set_params.start_datetime = moment().format("YYYY-MM-DDThh:mm:ss");
          } else {
            set_params.start_datetime = this.edit_value.start_datetime;
          }
          set_params.type = this.edit_value.type;
          set_params.animal_id = id;
          set_params.customer_id = customer_id;
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_production_history_one,
              variables: {
                object1: set_params,
                update_columns: ["type"],
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          this.animals[index].production_history_type =
            response.data.data.insert_production_history_one.type;
          this.animals[index].production_history_start_datetime =
            response.data.data.insert_production_history_one.start_datetime;
          this.$store
            .dispatch("DDB_GET_ANIMALS", this.query_params)
            .then(() => {
              this.animals = this.$store.getters.getAnimals;
            });
        } else {
          set_params[field] = this.edit_value;
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.update_animal_by_pk,
              variables: {
                pk_columns: { id: id },
                _set: set_params,
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );

          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          this.animals[index][field] =
            response.data.data.update_animal_by_pk[field];
        }
        this.edit_value = null;

        eventBus.$emit("add_button_alert", {
          msg: `Successfully updated the animal`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error updating the animal`,
          err: true,
        });
      }
    },
    async update_note(animal, index) {
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_user_note_by_pk,
            variables: {
              pk_columns: {
                id: this.animals[this.animals.indexOf(animal)].notes[index].id,
              },
              _set: { comment: this.edit_value_note },
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        this.animals[this.animals.indexOf(animal)].notes[index].comment =
          response.data.data.update_user_note_by_pk.comment;
        eventBus.$emit("add_button_alert", {
          msg: "Successfully updated the note",
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: "Error updating the note",
          err: true,
        });
      }
    },
    cancel_edit() {
      this.edit_value = null;
    },

    open_field(value, type) {
      if (type && type.includes("date")) {
        if (!value || !validateDateEmpty(value)) {
          this.edit_value = moment().format("YYYY-MM-DD");
        } else {
          this.edit_value = value;
          return false;
        }
      } else {
        if (typeof value == "object") {
          this.edit_value = { ...value };
          if (type == "tag" && value.tag_id == null) {
            this.edit_value.create_new = true;
          }
        } else {
          this.edit_value = value;
        }
      }
    },
    focus_animal_select(type, dob) {
      this.selection_type = {
        classification: type == "dam" ? "COW" : "BULL",
        dob: dob,
        type: type
      };
      this.selection_query_params = {
          classification: this.selection_type.classification,
          dob: this.selection_type.dob,
          search: null,
          search_dam: null,
          search_sire: null,
          active: type,
        }
    },
    close_edit() {
      // this.selection_query_params.active = false;
      //   this.edit_value = null;
    },
    capitalizeFirstLetter(string) {
      if (string) {
        if (
          string == "UNDEFINED" ||
          string == "Undefined" ||
          !string ||
          string == ""
        ) {
          return "-";
        }
        return (string.charAt(0) + string.slice(1).toLowerCase()).replaceAll(
          "_",
          " "
        );
      }
      return "-";
    },

    add_animal() {
      eventBus.$emit("add_animal_open");
    },
  },
  computed: {
    dam_selection_array() {
      if (this.selection_query_params.active == "sire" && this.edit_value.dam) {
        return [this.edit_value.dam];
      } else if (
        this.selection_query_params.active == "sire" &&
        !this.edit_value.dam
      ) {
        return [null];
      } else {
        return [...this.selection_animals];
      }
    },
    sire_selection_array() {
      if (this.selection_query_params.active == "dam" && this.edit_value.sire) {
        return [this.edit_value.sire];
      } else if (
        this.selection_query_params.active == "dam" &&
        !this.edit_value.sire
      ) {
        return [null];
      } else {
        return [...this.selection_animals];
      }
    },
    unit_system_multiplier() {
      if (this.$store.getters.getUserDDB.unit_system == "IMPERIAL") {
        return { length: 0.453592, weight: 2.54 };
      } else {
        return false;
      }
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    selection_animals() {
      var selection_animals = [];
      for (const i in this.$store.getters.getSelectionAnimals) {
        selection_animals.push({
          value: this.$store.getters.getSelectionAnimals[i].id,
          text: this.$store.getters.getSelectionAnimals[i].tag_label,
          tag_color: this.$store.getters.getSelectionAnimals[i].tag_color,
        });
      }
      return selection_animals;
    },

    enums() {
      var enums = {};
      enums.tag_color = this.$store.getters.getEnums.tag_color
        ? this.$store.getters.getEnums.tag_color
        : [];
      enums.production_type = this.$store.getters.getEnums.production_type
        ? this.$store.getters.getEnums.production_type
        : [];
      enums.sex_classification = this.$store.getters.getEnums.sex_classification
        ? this.$store.getters.getEnums.sex_classification
        : [];
      enums.animal_purpose = this.$store.getters.getEnums.animal_purpose
        ? this.$store.getters.getEnums.animal_purpose
        : [];
      enums.ownership_status = this.$store.getters.getEnums.ownership_status
        ? this.$store.getters.getEnums.ownership_status
        : [];
      enums.birth_ease = this.$store.getters.getEnums.birth_ease
        ? this.$store.getters.getEnums.birth_ease
        : [];
      enums.nurse_info = this.$store.getters.getEnums.nurse_info
        ? this.$store.getters.getEnums.nurse_info
        : [];
      enums.dam_status = this.$store.getters.getEnums.dam_status
        ? this.$store.getters.getEnums.dam_status
        : [];
      enums.status = this.$store.getters.getEnums.status
        ? this.$store.getters.getEnums.status
        : [];
      return enums;
    },
    sites() {
      var sites = [];
      for (const i in this.$store.getters.getSites) {
        sites.push({
          text: this.$store.getters.getSites[i].site_name,
          value: this.$store.getters.getSites[i].id,
        });
      }
      return sites;
    },
    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },
    cookie_bucket_footage() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },

    herds() {
      var herds = [];
      var herds_select = [{ text: "All", value: false }];
      for (const i in this.$store.getters.getHerds) {
        herds.push({
          text: this.$store.getters.getHerds[i].name,
          value: this.$store.getters.getHerds[i].id,
          herd_obj: this.$store.getters.getHerds[i],
        });

        herds_select.push({
          text: this.$store.getters.getHerds[i].name,
          value: this.$store.getters.getHerds[i].id,
        });
      }
      // herds.push({ name: "+", id: "+" });
      return [herds, herds_select];
    },

    selected_headers() {
      if (this.selected_table == 0) {
        return this.headers_general;
      } else if (this.selected_table == 2) {
        return this.headers_pedigree;
      } else if (this.selected_table == 1) {
        return this.headers_history;
      } else if (this.selected_table == 3) {
        return this.headers_measurements;
      } else if (this.selected_table == 4) {
        return this.headers_phenotypes;
      }
      return this.headers_general;
    },

    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    animal_count() {
      return this.$store.getters.getAnimalCount;
    },
    headers_general() {
      return [
        {
          text: "Tag",
          value: "tag_label",
          cellClass: this.show_heads
            ? this.editable
              ? "show_heads"
              : "show_heads_no_edit"
            : this.editable
            ? "non-hl"
            : "no_edit",
          width: "120px",
        },
        {
          text: "RFID",
          value: "rfid_current",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "180px",
          sortable: false,
        },
        {
          text: "Sex",
          value: "sex",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "140px",
        },
        {
          text: "Classification",
          value: "classification",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "150px",
        },
        {
          text: "Herd",
          value: "herd_name",
          width: "160px",
        },
        {
          text: "Birthday",
          value: "dob",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "150px",
        },

        {
          text: "Status",
          value: "status",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "140px",
        },
        {
          text: "Dam",
          value: "dam_tag_label",
          cellClass: this.show_heads
            ? this.editable
              ? "show_heads"
              : "show_heads_no_edit"
            : this.editable
            ? "non-hl"
            : "no_edit",
          width: "120px",
        },
        {
          text: "Sire",
          value: "sire_tag_label",
          cellClass: this.show_heads
            ? this.editable
              ? "show_heads"
              : "show_heads_no_edit"
            : this.editable
            ? "non-hl"
            : "no_edit",
          width: "120px",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headers_history() {
      return [
        {
          text: "Tag",
          value: "tag_label",
          cellClass: this.show_heads
            ? this.editable
              ? "show_heads"
              : "show_heads_no_edit"
            : this.editable
            ? "non-hl"
            : "no_edit",
          width: "120px",
        },
        {
          text: "Arrival",
          value: "site_doa",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Purpose",
          value: "purpose",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Production Type",
          value: "production_history_type",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Ownership",
          value: "ownership_status",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Birth",
          value: "birth_ease",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Nursing",
          value: "nurse_info",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Death",
          value: "cod",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headers_breeding() {
      return [
        {
          text: "Tag",
          value: "tag_label",
          cellClass: this.show_heads
            ? this.editable
              ? "show_heads"
              : "show_heads_no_edit"
            : this.editable
            ? "non-hl"
            : "no_edit",
          width: "120px",
        },
        {
          text: "RFID",
          value: "rfid_current",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "180px",
        },
        {
          text: "Dam",
          value: "dam_tag_label",
          cellClass: this.show_heads
            ? this.editable
              ? "show_heads"
              : "show_heads_no_edit"
            : this.editable
            ? "non-hl"
            : "no_edit",
          width: "120px",
        },
        {
          text: "Sire",
          value: "sire_tag_label",
          cellClass: this.show_heads
            ? this.editable
              ? "show_heads"
              : "show_heads_no_edit"
            : this.editable
            ? "non-hl"
            : "no_edit",
          width: "120px",
        },
        {
          text: "Type",
          value: "breeding_history_type",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },

        {
          text: "Pregnant",
          value: "breeding_history_pregnant",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        // {
        //   text: "Breeding Date",
        //   value: "animal_breeding_date",
        //   cellClass: this.editable ? "non-hl" : "no_edit",
        // },
        {
          text: "Dam Status",
          value: "dam_status",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headers_pedigree() {
      return [
        {
          text: "Tag",
          value: "tag_label",
          cellClass: this.show_heads
            ? this.editable
              ? "show_heads"
              : "show_heads_no_edit"
            : this.editable
            ? "non-hl"
            : "no_edit",
          width: "120px",
        },
        {
          text: "Purebred",
          value: "purebred",
          width: "110px",
          cellClass: this.editable ? "non-hl-pedigree" : "no_edit",
          sortable: false,
        },
        {
          text: "Reg. Name",
          value: "pedigree.registered_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Reg. No.",
          value: "pedigree.registration_number",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Reg. Association",
          value: "pedigree.registration_association",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Tattoo",
          value: "pedigree.tattoo",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Reg. Date",
          value: "pedigree.registration_date",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headers_measurements() {
      return [
        {
          text: "Tag",
          value: "tag_label",
          cellClass: this.show_heads
            ? this.editable
              ? "show_heads"
              : "show_heads_no_edit"
            : this.editable
            ? "non-hl"
            : "no_edit",
          width: "120px",
        },
        {
          text: this.unit_system_multiplier ? "Height (in)" : "Height (cm)",
          value: "measurement_height",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: this.unit_system_multiplier ? "Birth (lbs)" : "Birth (kg)",
          value: "measurement_weight_birth",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: this.unit_system_multiplier ? "Weaning (lbs)" : "Weaning (kg)",
          value: "measurement_weight_weaning",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: this.unit_system_multiplier ? "Yealing (lbs)" : "Yealing (kg)",
          value: "measurement_weight_yearling",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: this.unit_system_multiplier ? "Mature (lbs)" : "Mature (kg)",
          value: "measurement_weight_mature",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headers_phenotypes() {
      return [
        {
          text: "Tag",
          value: "tag_label",
          cellClass: this.show_heads
            ? this.editable
              ? "show_heads"
              : "show_heads_no_edit"
            : this.editable
            ? "non-hl"
            : "no_edit",
          width: "120px",
        },
        {
          text: "",
          value: "add_phenotype",
          cellClass: this.editable ? "non-hl" : "no_edit",
          sortable: false,
        },
        {
          text: "BCS",
          value: "phenotype_bcs",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Claw",
          value: "phenotype_claw",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Hoof",
          value: "phenotype_hoof",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Leg",
          value: "phenotype_leg",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Date",
          value: "phenotype_date",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },

        { text: "", value: "data-table-expand" },
      ];
    },
    headers_notes() {
      return [
        {
          text: "Date",
          value: "datetime",
          width: "120px",
        },
        {
          text: "Note",
          value: "comment",
          cellClass: "non-hl",
        },
        {
          text: "",
          value: "delete_note",
          width: "40px",
        },
      ];
    },

    sort_direction() {
      return this.options.sortDesc && this.options.sortDesc[0] ? "desc" : "asc";
    },
    query_params() {
      if (this.customer_id) {
        return {
          herd: this.selected_herd,
          search: this.search,
          sort: this.options.sortBy ? this.options.sortBy[0] : "tag_label",
          sort_direction: this.sort_direction,
          limit: this.options.itemsPerPage,
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          filter: {
            tag_color: this.tag_color_filter,
            sex: this.sex_filter,
            classification: this.classification_filter,
            status: this.status_filter,
            purpose: this.purpose_filter,
            production: this.production_filter,
            ownership_status: this.ownership_filter,
            birth_ease: this.birth_filter,
            nurse_info: this.nursing_filter,
            cod: this.death_filter,
          },
        };
      }
      return undefined;
    },
  },

  created() {
    eventBus.$on("change_animal", (dir) => {
      if (dir == 0) {
        this.select_animal(this.animals[this.selected_animal_index - 1]);
      } else if (dir == 1) {
        this.select_animal(this.animals[this.selected_animal_index + 1]);
      } else {
        this.select_animal(dir);
      }
    });
    eventBus.$on("get_animals", () => {
      this.$store.dispatch("DDB_GET_ANIMALS", this.query_params).then(() => {
        this.animals = this.$store.getters.getAnimals;
      });
    });
    if (localStorage.getItem("show_heads") === "true") {
      this.show_heads = true;
    }
    if (this.animal_index != "all") {
      this.search = this.animal_index;
    }
  },
  watch: {
    edit_herd_dialog() {
      if (!this.edit_herd_dialog) {
        this.herd_edited = {};
      }
    },
    show_heads() {
      localStorage.setItem("show_heads", this.show_heads);
    },

    query_params() {
      if (this.query_params) {
        this.$store.dispatch("DDB_GET_ANIMALS", this.query_params).then(() => {
          this.animals = this.$store.getters.getAnimals;
          // if (this.animal_index != "all" && this.first_time == true) {
          //   this.first_time = false;
          //   this.select_animal(this.animals[this.animal_index].id);
          // }
        });
      }
    },

    selection_query_params: {
      handler: function () {
        if (this.selection_query_params.active) {
          this.$store.dispatch(
            "DDB_GET_SELECTION_ANIMALS",
            this.selection_query_params
          );
        }
      },
      deep: true,
    },

    expand_dialog() {
      if (!this.expand_dialog) {
        this.selected_animal_id = null;
        this.selected_animal_index_pos = null;
        this.selected_animal_index = null;
      }
    },

    animals() {
      this.loading = false;
    },
  },
};
</script>
<style lang="css" scoped>
.animal_tabs {
  max-width: 720px;
}
</style>